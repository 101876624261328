import { ChangeDetectionStrategy, Component, HostListener, inject } from '@angular/core';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { scan } from 'rxjs';

import { LogService } from '../shared/services/log.service';

@Component({
  selector: 'app-log-stream',
  standalone: true,
  templateUrl: './log-stream.component.html',
  styleUrls: ['./log-stream.component.scss'],
  imports: [AsyncPipe, NgFor, NgIf, ClipboardModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LogStreamComponent {
  private readonly logService = inject(LogService);

  public showDebugStream = false;
  public readonly logs$ = this.logService.logs$.pipe(scan((acc, curr) => [...acc, curr], [] as string[]));

  @HostListener('document:keydown', ['$event'])
  public toggleShowDebugStream(event: KeyboardEvent) {
    if (event.ctrlKey && event.code === 'KeyI') {
      event.preventDefault();

      this.logService.toggleDebugStream();
      this.showDebugStream = !this.showDebugStream;
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpEvent, HttpRequest } from '@angular/common/http';
import { HttpHandler, HttpInterceptor } from '@angular/common/http';

import { concatMap, Observable } from 'rxjs';

import { AuthService } from '../auth.service';

import { isAnonymousRoute, isBackendRoute, useSSOHeader } from '../../utils/interceptors/backend-api.util';

@Injectable()
export class BackendApiInterceptor implements HttpInterceptor {
  public constructor(private readonly authService: AuthService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (isBackendRoute(request.url)) {
      if (isAnonymousRoute(request.url)) return next.handle(request);
      if (useSSOHeader(request.url)) return this.addSSOTokenToRequest(request, next);

      return this.addAccessTokenToRequest(request, next);
    }

    return next.handle(request);
  }

  private addAccessTokenToRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.getCachedTokensOrAuthenticate('Backend', true).pipe(
      concatMap((token) => {
        const authHeader = `Bearer ${token.accessToken}`;
        const requestWithAuthHeader = request.clone({ setHeaders: { Authorization: authHeader } });

        return next.handle(requestWithAuthHeader);
      }),
    );
  }

  private addSSOTokenToRequest(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.authService.getAndCacheSSOToken(false).pipe(
      concatMap((ssoToken) => {
        const authHeader = `Bearer ${ssoToken.access_token}`;
        const requestWithSSOTokenHeader = request.clone({ setHeaders: { Authorization: authHeader } });

        return next.handle(requestWithSSOTokenHeader);
      }),
    );
  }
}

import { ANONYMOUS_ROUTES, SSO_ROUTES } from '../../models/interceptors/backend-api.model';

import { environment } from 'src/environments/environment';

export function isBackendRoute(url: string): boolean {
  return url.startsWith(`${environment.backendUrl}/api`);
}

export function isAnonymousRoute(url: string): boolean {
  for (const anonymousRoute of ANONYMOUS_ROUTES) if (url.startsWith(anonymousRoute)) return true;

  return false;
}

export function useSSOHeader(url: string): boolean {
  for (const ssoRoute of SSO_ROUTES) if (url.startsWith(ssoRoute)) return true;

  return false;
}

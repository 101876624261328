import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Output,
  signal,
} from '@angular/core';

import { TranslocoModule } from '@ngneat/transloco';

import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';

import { fadeInOut } from '../../utils/animation.util';

@Component({
  standalone: true,
  selector: 'app-settings-button',
  templateUrl: './settings-button.component.html',
  styleUrls: ['./settings-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoModule, TooltipDirective],
  animations: [fadeInOut],
})
export class SettingsButtonComponent {
  public isHovering = signal(false);

  @Output() public readonly buttonClicked = new EventEmitter<void>();

  @HostBinding('@fadeInOut') public fadeInOut = {
    value: true,
    params: { fadeInMs: 250, fadeOutMs: 0 },
  };

  @HostListener('pointerenter')
  public onPointerEnter(): void {
    this.isHovering.set(true);
  }

  @HostListener('pointerleave')
  public onPointerLeave(): void {
    this.isHovering.set(false);
  }

  @HostListener('click')
  public click(): void {
    this.buttonClicked.emit();
  }
}

import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DialogModule } from '@angular/cdk/dialog';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { provideTransloco, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { QuillModule } from 'ngx-quill';

import { AppComponent } from './app.component';
import { LogStreamComponent } from './log-stream/log-stream.component';
import { ErrorComponent } from './shared/components/error/error.component';
import { SettingsButtonComponent } from './shared/components/settings-button/settings-button.component';
import { SupportButtonComponent } from './shared/components/support-button/support-button.component';
import { TourTooltipComponent } from './shared/components/tour-tooltip/tour-tooltip.component';

import { ApplicationInsightsService } from './shared/services/application-insights.service';
import { AuthService } from './shared/services/auth.service';
import { CacheService } from './shared/services/cache.service';
import { LogService } from './shared/services/log.service';
import { SidebarService } from './shared/services/sidebar.service';

import { SafePipe } from './shared/pipes/safe.pipe';

import { TourDirective } from './shared/directives/tour/tour.directive';

import { initializeApp } from './shared/utils/components/app.util';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/components/shared.module';

import { BackendApiInterceptor } from './shared/services/interceptors/backend-api.interceptor';
import { ClientOfflineInterceptor } from './shared/services/interceptors/client-offline.interceptor';
import { GraphApiInterceptor } from './shared/services/interceptors/graph-api.interceptor';

import { environment } from '../environments/environment';
import { TRANSLOCO_CONFIG, TranslocoHttpLoader } from './transloco-loader';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.enableServiceWorker,
      registrationStrategy: 'registerImmediately',
    }),
    TranslocoModule,
    BrowserAnimationsModule,
    DialogModule,
    SafePipe,
    SupportButtonComponent,
    QuillModule.forRoot(),
    ErrorComponent,
    LogStreamComponent,
    TourDirective,
    TourTooltipComponent,
    SettingsButtonComponent,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [
        AuthService,
        CacheService,
        TranslocoService,
        ApplicationInsightsService,
        SidebarService,
        Router,
        HttpClient,
        LogService,
      ],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GraphApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: BackendApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ClientOfflineInterceptor,
      multi: true,
    },
    provideTransloco({
      config: TRANSLOCO_CONFIG,
      loader: TranslocoHttpLoader,
    }),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

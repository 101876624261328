<ng-container *transloco="let tSettings; read: 'shared.settings'">
  <img
    src="assets/img/icons/settings.svg"
    [appTooltip]="tSettings('tooltip')"
    [appTooltipDirection]="'top'"
    [appTooltipMaxWidth]="'unset'"
    [appTooltipOpen]="isHovering()"
    [appTooltipPadding]="'scale-by-office(1.5) scale-by-office(2)'"
  />
</ng-container>

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { HttpHandler, HttpInterceptor } from '@angular/common/http';

import { BehaviorSubject, catchError, concatMap, delay, map, Observable, of, switchMap, take, throwError } from 'rxjs';
import { differenceInMilliseconds, subSeconds } from 'date-fns';

import { AuthService } from '../auth.service';

import { getRetryAfterDate } from '../../utils/interceptors/graph-api.util';

const GRAPH_API_BASE = 'https://graph.microsoft.com/';

@Injectable()
export class GraphApiInterceptor implements HttpInterceptor {
  private retryAfterTooManyRequests = new BehaviorSubject<Date>(subSeconds(new Date(), 1));

  public constructor(private readonly authService: AuthService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.startsWith(GRAPH_API_BASE)) {
      return this.handleGraphApiRequest(request, next);
    }

    return next.handle(request);
  }

  private handleGraphApiRequest(
    initialRequest: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    return this.addAccessTokenToRequest(initialRequest).pipe(
      concatMap((request) => this.waitUntilRequestCanBeSent(request)),
      concatMap((request) => next.handle(request)),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 429) {
          const retryAfter = error.headers.get('Retry-After');
          this.retryAfterTooManyRequests.next(getRetryAfterDate(retryAfter));

          return this.handleGraphApiRequest(initialRequest, next);
        }

        return throwError(() => error);
      }),
    );
  }

  private addAccessTokenToRequest(request: HttpRequest<unknown>): Observable<HttpRequest<unknown>> {
    return this.authService
      .getCachedTokensOrAuthenticate('Graph', true)
      .pipe(map((token) => request.clone({ setHeaders: { Authorization: `Bearer ${token.accessToken}` } })));
  }

  private waitUntilRequestCanBeSent(request: HttpRequest<unknown>): Observable<HttpRequest<unknown>> {
    return this.retryAfterTooManyRequests.pipe(
      // If a no request is triggered while we are inside the delay, cancel and check again
      switchMap((retryAfter) => {
        const now = new Date();
        if (retryAfter <= now) return of(delay(0));

        const retryAfterMs = Math.max(0, differenceInMilliseconds(retryAfter, now));
        return of(delay(retryAfterMs));
      }),
      take(1),
      map(() => request),
    );
  }
}

<img
  [appTooltip]="tooltip"
  [appTooltipDirection]="'top'"
  [appTooltipMaxWidth]="'unset'"
  [appTooltipOpen]="isHovering"
  [appTooltipPadding]="'scale-by-office(1.5) scale-by-office(2)'"
  [src]="'assets/img/icons/dog-face_' + (isHovering || isTourActive ? 'animated' : 'static') + '.png'"
/>

<ng-template #tooltip>
  <div *transloco="let t; read: 'shared.supportButton'" class="tooltip-container">
    <div class="tooltip-title">{{ t("chatWithBNear") }}</div>
    <div class="tooltip-body">{{ t("forSupport") }}</div>
  </div>
</ng-template>

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { catchError, concatMap, from, Observable, of, retry, tap, throwError, timeout } from 'rxjs';
import { app, HostClientType } from '@microsoft/teams-js';
import { TranslocoService } from '@ngneat/transloco';
import Dexie from 'dexie';

import { ApplicationInsightsService } from '../../services/application-insights.service';
import { AuthService } from '../../services/auth.service';
import { CacheService } from '../../services/cache.service';
import { LogService } from '../../services/log.service';
import { SidebarService } from '../../services/sidebar.service';

import { Location, UserInfo } from '../../models/services/user.model';

import { reloadApp } from '../network.util';

export const INDEXED_DB_INITIALIZATION_FAILED_SEARCH_PARAM = 'indexedDbInitializationFailed';

export function isIOS(hostClientType?: HostClientType): boolean {
  return hostClientType === HostClientType.ios || hostClientType === HostClientType.ipados;
}

export function getNavigateToUrl(userInfo: UserInfo | null, channelTeamObjectId?: string): Observable<string> {
  const user = userInfo?.user;
  const hasUsedAppAtLeastOnce = userInfo && user && userInfo.hasUsedAppAtLeastOnce;
  if (!hasUsedAppAtLeastOnce) return of('lobby?showTour=true');

  const isCheckedIn = user.baseLocation !== Location.Inactive;
  const hasValidLicense = userInfo.license.isValid;

  if (!isCheckedIn || !hasValidLicense) return of('lobby');

  const teamObjectId = channelTeamObjectId || user.currentTeamObjectId;
  return of(`elevator?teamObjectId=${teamObjectId}`);
}

export function initializeApp(
  authService: AuthService,
  cacheService: CacheService,
  translocoService: TranslocoService,
  applicationInsightsService: ApplicationInsightsService,
  sidebarService: SidebarService,
  router: Router,
  httpClient: HttpClient,
  logService: LogService,
): () => Observable<unknown> {
  // Uncomment to clear the cache programmatically
  // Dexie.delete('appData');

  // Initialize the teams context and cache service first to be sure that the cache service is ready to use
  return () =>
    from(app.initialize()).pipe(
      concatMap(() => authService.initializeContext()),
      timeout(5_000),

      tap((context) => {
        logService.logDebug('initializeApp', context);

        applicationInsightsService.initializeAppInsights(context);

        if (context.app.locale.startsWith('pl')) {
          translocoService.setActiveLang('pl');
        } else if (context.app.locale.startsWith('de')) {
          translocoService.setActiveLang('de');
        }
      }),

      concatMap((context) =>
        cacheService.initialize(context).pipe(
          retry({
            count: 3,
            delay: 1_000,
          }),
          catchError(() => {
            Dexie.delete('appData');

            return cacheService
              .initialize(context)
              .pipe(
                catchError((err) => throwError(() => new Error(`indexed-db initialization failed: ${err.message}`))),
              );
          }),
        ),
      ),

      concatMap(() => sidebarService.initialize()),

      catchError((error) => {
        console.error(error);

        applicationInsightsService.initializeAppInsights();
        applicationInsightsService.logCustomException(error);

        if (error instanceof Error && error.message.startsWith('indexed-db initialization failed')) {
          return router.navigate([], { queryParams: { [INDEXED_DB_INITIALIZATION_FAILED_SEARCH_PARAM]: 'true' } });
        }

        reloadApp(httpClient, logService, cacheService, false);

        throw error;
      }),
    );
}

import { ChangeDetectorRef, Component, inject, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';
import { HttpClient } from '@angular/common/http';

import {
  catchError,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  finalize,
  forkJoin,
  from,
  interval,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
  takeUntil,
  tap,
  throwError,
  timer,
} from 'rxjs';
import { concatMap, map, retry } from 'rxjs';
import { app } from '@microsoft/teams-js';
import { differenceInMilliseconds, subHours } from 'date-fns';

import { OfflineComponent } from './shared/components/toasts/offline/offline.component';

import { ApplicationInsightsService } from './shared/services/application-insights.service';
import { AuthService } from './shared/services/auth.service';
import { CacheService } from './shared/services/cache.service';
import { DialogService } from './shared/services/dialog.service';
import { GlobalSpinnerService } from './shared/services/global-spinner.service';
import { LogService } from './shared/services/log.service';
import { TenantService } from './shared/services/tenant.service';
import { ToastService } from './shared/services/toast/toast.service';
import { UserService } from './shared/services/user.service';

import {
  DEVICE_SUSPENSION_CHECK_PERIOD_MS,
  SERVICE_WORKER_UPDATE_CHECK_PERIOD_MS,
  SSO_AND_ACCESS_TOKEN_UPDATE_INITIAL_MS,
  SSO_AND_ACCESS_TOKEN_UPDATE_PERIOD_MS,
} from './shared/models/components/app.model';
import { UserOpenedAppEvent } from './shared/models/services/application-insights.model';
import { BackendAccessTokenResult } from './shared/models/services/auth.model';
import { ToastRef } from './shared/models/services/toast.model';
import { OFFICE_TOUR_STEPS } from './shared/models/tour.model';

import {
  getNavigateToUrl,
  INDEXED_DB_INITIALIZATION_FAILED_SEARCH_PARAM,
  isIOS,
} from './shared/utils/components/app.util';
import { waitForBackendConnectivityOrTimeout } from './shared/utils/network.util';
import { reloadApp } from './shared/utils/network.util';
import { filterNullish } from './shared/utils/rxjs.util';

import { AUTHENTICATION_REQUIRES_CONSENT } from './shared/constants';

const LOGGING_CONTEXT = 'AppComponent';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private readonly userService = inject(UserService);
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly applicationInsightsService = inject(ApplicationInsightsService);
  private readonly swUpdate = inject(SwUpdate);
  private readonly globalSpinnerService = inject(GlobalSpinnerService);
  private readonly httpClient = inject(HttpClient);
  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly renderer = inject(Renderer2);
  private readonly cacheService = inject(CacheService);
  private readonly toastService = inject(ToastService);
  private readonly logService = inject(LogService);
  private readonly dialogService = inject(DialogService);

  private unsubscribeRetrySilentAuthentication?: Subject<void>;
  private offlineToastRef?: ToastRef;

  public readonly authService = inject(AuthService);
  public readonly tenantService = inject(TenantService);

  public inTeams = true;
  public startTrialPageUrl = `https://bnear.io/self-service-welcome/`;
  public showStartTrialPage = false;
  public isAdminConsentGiven?: boolean;

  public authenticationInProgress = false;
  public completedAuthentication = false;
  public authenticationFailed = false;
  public indexedDbInitializationFailed = false;

  public hasInternetConnectivity = true;
  public showSpinner = true;

  public enableInitialOfflineScreen = true;

  public OfficeTourSteps = OFFICE_TOUR_STEPS;

  public readonly userInfo$ = this.userService.userInfo$.pipe(filterNullish());

  public readonly userFirstName$ = this.userService
    .getCachedOrFreshGraphUser(this.authService.context!.user!.id!)
    .pipe(map((user) => user?.firstName || user?.displayName));

  public ngOnInit(): void {
    this.applicationInsightsService.logCustomEvent(new UserOpenedAppEvent(this.authService));

    if (this.authService.context?.app.locale.startsWith('de')) {
      this.startTrialPageUrl = `https://bnear.io/de/self-service-welcome/`;
    }

    // This allows hovering by short taps on iOS
    if (isIOS(this.authService.context?.app.host.clientType)) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      this.renderer.listen('document', 'touchstart', () => {});
    }

    this.checkDeviceSuspensionInBackground();
    this.handleGlobalSpinnerEventsInBackground();

    // Check if the APP_INITIALIZER failed
    // FIXME: If this is the case, the app is not updated correctly via the service worker
    this.indexedDbInitializationFailed =
      this.route.snapshot.queryParams[INDEXED_DB_INITIALIZATION_FAILED_SEARCH_PARAM] === 'true';
    if (this.indexedDbInitializationFailed) {
      this.showSpinner = false;
      app.notifySuccess();

      return;
    }

    this.startServiceWorkerAndUpdateCheck()
      .pipe(
        filter((updateAvailable) => !updateAvailable),

        concatMap(() => this.tenantService.isAdminConsentGiven()),
        tap(
          (isAdminConsentGiven) =>
            ([this.isAdminConsentGiven, this.showStartTrialPage] = [isAdminConsentGiven, !isAdminConsentGiven]),
        ),
        distinctUntilChanged(),
        filter((isAdminConsentGiven) => isAdminConsentGiven),

        concatMap(() => this.authenticateUser(false)),
        concatMap(() => this.fetchInitialDataAndRedirectUser()),
      )
      .subscribe();

    app.notifySuccess();
  }

  // We need to trigger auth after a button click on the web because of popup blockers
  public triggerAuthentication(): void {
    this.showSpinner = true;

    // If any background authentication is underway, stop it
    this.stopSilentBackgroundAuthenticationRetry();

    this.authService
      .triggerAuthenticationPopup()
      .pipe(
        concatMap(() => this.authenticateUser(true)),
        concatMap(() => this.fetchInitialDataAndRedirectUser()),
      )
      .subscribe({
        error: (error) => {
          this.applicationInsightsService.logCustomException(error);

          this.showSpinner = false;

          throw error;
        },
      });
  }

  public openSettings(): void {
    this.dialogService.openSettingsDialog().subscribe((settingsChanged) => {
      if (!settingsChanged) return;

      reloadApp(this.httpClient, this.logService, this.cacheService, false);
    });
  }

  private checkDeviceSuspensionInBackground(): void {
    let then = new Date();
    interval(DEVICE_SUSPENSION_CHECK_PERIOD_MS).subscribe(() => {
      const now = new Date();
      if (differenceInMilliseconds(now, then) > DEVICE_SUSPENSION_CHECK_PERIOD_MS * 2) {
        this.logService.logDebug(LOGGING_CONTEXT, `Device was probably suspended, reloading`);
        reloadApp(this.httpClient, this.logService, this.cacheService, false);
      }

      then = now;
    });
  }

  private handleGlobalSpinnerEventsInBackground(): void {
    this.globalSpinnerService.showGlobalSpinner.subscribe((showSpinner) => {
      this.showSpinner = showSpinner;
      this.changeDetectorRef.detectChanges();
    });

    this.globalSpinnerService.showGlobalOfflineScreen.subscribe(() => {
      this.logService.logDebug(LOGGING_CONTEXT, 'Show offline screen');

      if (!this.enableInitialOfflineScreen && !this.offlineToastRef) {
        this.offlineToastRef = this.toastService.show(OfflineComponent, { autoCloseEnabled: false });
      }

      this.showOfflineScreenAndReloadIfOnline().subscribe();
      this.changeDetectorRef.detectChanges();
    });
  }

  // Returns `true` if a new version is available
  private startServiceWorkerAndUpdateCheck(): Observable<boolean> {
    if (!this.swUpdate.isEnabled) {
      this.logService.log(LOGGING_CONTEXT, 'Service worker disabled');

      return of(false);
    }

    this.swUpdate.versionUpdates
      .pipe(
        concatMap((versionEvent) => {
          if (versionEvent.type === 'VERSION_DETECTED') {
            this.logService.logDebug(LOGGING_CONTEXT, 'Update available...');

            this.showSpinner = true;
          } else if (versionEvent.type === 'VERSION_READY') {
            this.logService.logDebug(LOGGING_CONTEXT, 'Update ready...');

            return from(this.swUpdate.activateUpdate()).pipe(
              finalize(() => {
                reloadApp(this.httpClient, this.logService, this.cacheService, true);
                return of(null);
              }),
            );
          } else if (versionEvent.type === 'VERSION_INSTALLATION_FAILED') {
            this.logService.logDebug(LOGGING_CONTEXT, 'Update installation failed...');

            reloadApp(this.httpClient, this.logService, this.cacheService, true);
            return of(null);
          }

          return of(null);
        }),
      )
      .subscribe();

    this.swUpdate.unrecoverable.subscribe(() => {
      this.logService.logDebug(LOGGING_CONTEXT, 'Unrecoverable state...');
      reloadApp(this.httpClient, this.logService, this.cacheService, true);
    });

    interval(SERVICE_WORKER_UPDATE_CHECK_PERIOD_MS)
      .pipe(
        concatMap(() => this.cacheService.updateGeneralData({ lastVersionCheck: new Date() })),
        switchMap(() => this.swUpdate.checkForUpdate()),
      )
      .subscribe();

    return this.cacheService.getGeneralData().pipe(
      concatMap((generalData) => {
        const now = new Date();
        const blockingVersionCheckRequired = generalData.lastVersionCheck < subHours(now, 5);

        if (blockingVersionCheckRequired) {
          this.logService.logDebug(LOGGING_CONTEXT, 'Waiting to check version update...');

          return this.cacheService
            .updateGeneralData({ lastVersionCheck: now })
            .pipe(concatMap(() => this.swUpdate.checkForUpdate()));
        }

        return of(false);
      }),
    );
  }

  private stopSilentBackgroundAuthenticationRetry(): void {
    if (this.unsubscribeRetrySilentAuthentication) {
      this.unsubscribeRetrySilentAuthentication.next();
      this.unsubscribeRetrySilentAuthentication.complete();
    }
  }

  // The authentication & initialization flow works as follows:
  // 1) Get SSO Token
  // 2) Exchange SSO token with graph API token and backend API access token
  // 2.1) If consent is required, trigger consent popup and then exchange tokens
  // 3) Check if admin consent is given
  // 4) Get or create user and redirect to office or team selection
  private authenticateUser(
    assumeUserConsentIsGiven: boolean,
  ): Observable<BackendAccessTokenResult | BackendAccessTokenResult[]> {
    return this.cacheService.getBackendData().pipe(
      concatMap((backendData) => {
        const initialAuthRequest = this.authService
          .getCachedTokensOrAuthenticate('Graph', false, true)
          .pipe(concatMap(() => this.authService.getCachedTokensOrAuthenticate('Backend', true)));

        const parallelAuthRequest = forkJoin([
          this.authService.getCachedTokensOrAuthenticate('Backend', true),
          this.authService.getCachedTokensOrAuthenticate('Graph', assumeUserConsentIsGiven, true),
        ]);

        // We will (probably) never change the backend resource consent, so if the user consented at least once,
        // we can assume that only the graph consent may have changed so we can get both tokens in parallel
        const authenticationRequest: Observable<BackendAccessTokenResult | BackendAccessTokenResult[]> =
          assumeUserConsentIsGiven || backendData.isAdminConsentGiven ? parallelAuthRequest : initialAuthRequest;

        // If the authentication fails, silently retry in the background
        return authenticationRequest.pipe(
          tap(() => {
            this.completedAuthentication = true;
            this.authenticationInProgress = false;
          }),
          catchError((err) => {
            this.showSpinner = false;
            this.authenticationInProgress = false;

            const authenticationCancelledByUser = err === 'CancelledByUser';
            const authenticationRequiresConsent = err === AUTHENTICATION_REQUIRES_CONSENT;
            if (!this.hasInternetConnectivity || authenticationCancelledByUser) {
              return EMPTY;
            }

            if (!authenticationRequiresConsent) {
              this.applicationInsightsService.logCustomException(err);
            }

            this.logService.logDebug(LOGGING_CONTEXT, 'Authentication failed, retrying in background');

            this.unsubscribeRetrySilentAuthentication = new Subject();
            return authenticationRequest.pipe(
              catchError((err) => {
                if (!this.hasInternetConnectivity) return EMPTY;

                throw err;
              }),
              retry({
                delay: 5_000,
                count: 4,
              }),
              catchError((err) => {
                reloadApp(this.httpClient, this.logService, this.cacheService, true);

                return throwError(err);
              }),
              takeUntil(this.unsubscribeRetrySilentAuthentication!),
            );
          }),
        );
      }),
    );
  }

  private fetchInitialDataAndRedirectUser(): Observable<unknown> {
    let periodicBackgroundTokenRefreshStarted = false;

    return combineLatest([
      this.userService.getUserInfo(),
      merge(
        this.cacheService.getBackendData().pipe(map(({ tenantSettings }) => tenantSettings)),
        this.tenantService.getSettings(),
      ),
    ]).pipe(
      concatMap(([userInfo, settings]) => {
        if (!settings) return of(null);

        if (!periodicBackgroundTokenRefreshStarted) {
          periodicBackgroundTokenRefreshStarted = true;

          // Periodically refresh tokens in background
          timer(SSO_AND_ACCESS_TOKEN_UPDATE_INITIAL_MS, SSO_AND_ACCESS_TOKEN_UPDATE_PERIOD_MS)
            .pipe(switchMap(() => this.authService.refreshCachedTokens()))
            .subscribe();
        }

        this.enableInitialOfflineScreen = false;

        const channelTeamObjectId = this.authService.context!.team?.groupId;
        return getNavigateToUrl(userInfo, channelTeamObjectId);
      }),

      // Only navigate if the URL has changed to avoid a reload
      filterNullish(),
      distinctUntilChanged(),
      concatMap((url) => this.router.navigateByUrl(url)),
    );
  }

  private showOfflineScreenAndReloadIfOnline(): Observable<unknown> {
    this.hasInternetConnectivity = false;
    this.showSpinner = false;

    // Retry every 5 secs
    return waitForBackendConnectivityOrTimeout(
      this.httpClient,
      5 * 1_000,
      // Set the delay to any value bigger than the timeout, so that the timeout determines the retry period
      10 * 1_000,
      this.completedAuthentication,
    ).pipe(
      // Map isOnline == false to an error so that we can retry periodically
      map((isOnline) => {
        if (isOnline) return true;

        throw new Error('offline');
      }),
      retry(),
      tap(() => reloadApp(this.httpClient, this.logService, this.cacheService, true)),
    );
  }
}

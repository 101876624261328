import { addSeconds } from 'date-fns';

export function getRetryAfterDate(retryAfter: string | null): Date {
  if (!retryAfter) return new Date();

  const retryAfterSeconds = parseFloat(retryAfter);
  if (!isNaN(retryAfterSeconds)) return addSeconds(new Date(), retryAfterSeconds);

  return new Date(retryAfter);
}

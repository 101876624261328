import { ChangeDetectionStrategy, Component, HostBinding, HostListener, inject, Input } from '@angular/core';
import { Router } from '@angular/router';

import { from } from 'rxjs';
import { app } from '@microsoft/teams-js';
import { TranslocoModule } from '@ngneat/transloco';

import { AuthService } from '../../services/auth.service';
import { ChatService } from '../../services/chat.service';

import { TooltipDirective } from '../../directives/tooltip/tooltip.directive';

import { fadeInOut } from '../../utils/animation.util';
import { openChat } from '../../utils/services/call.util';

import { DEEP_LINK_BASE, SUPPORT_USER_ID, SUPPORT_USER_PRINCIPAL_NAME } from '../../constants';

@Component({
  standalone: true,
  selector: 'app-support-button',
  templateUrl: './support-button.component.html',
  styleUrls: ['./support-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [TranslocoModule, TooltipDirective],
  animations: [fadeInOut],
})
export class SupportButtonComponent {
  private readonly authService = inject(AuthService);
  private readonly chatService = inject(ChatService);
  private readonly router = inject(Router);

  public isHovering = false;

  @Input({ required: true }) public chatFeatureEnabled!: boolean;
  @Input({ required: true }) public isTourActive!: boolean;

  @HostBinding('@fadeInOut') public fadeInOut = {
    value: true,
    params: { fadeInMs: 250, fadeOutMs: 0 },
  };

  @HostListener('pointerenter')
  public onPointerEnter(): void {
    this.isHovering = true;
  }

  @HostListener('pointerleave')
  public onPointerLeave(): void {
    this.isHovering = false;
  }

  @HostListener('click')
  public openSupportChat(): void {
    const chatFeatureAvailable = this.chatFeatureEnabled && this.router.url.startsWith('/office');

    if (chatFeatureAvailable) {
      openChat(this.authService.context!.user!.id, SUPPORT_USER_ID, undefined, this.chatService).subscribe();
    } else {
      const chatDeepLink = `${DEEP_LINK_BASE}/chat/0/0?users=${this.authService.context!.user!
        .userPrincipalName!},${SUPPORT_USER_PRINCIPAL_NAME}`;
      from(app.openLink(chatDeepLink)).subscribe();
    }
  }
}

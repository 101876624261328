import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Translation, TranslocoLoader } from '@ngneat/transloco';

import { environment } from '../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  public constructor(private http: HttpClient) {}

  public getTranslation(lang: string) {
    return this.http.get<Translation>(`/assets/i18n/${lang}.json`);
  }
}

export const TRANSLOCO_CONFIG = {
  availableLangs: ['en', 'de', 'pl'],
  defaultLang: 'en',
  reRenderOnLangChange: false,
  prodMode: environment.production,
};

import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgFor, NgIf, NgStyle } from '@angular/common';

@Component({
  selector: 'app-error',
  standalone: true,
  imports: [NgIf, NgStyle, NgFor],
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ErrorComponent implements OnChanges {
  @Input() public topImageUrl?: string;
  @Input() public heading?: string;
  @Input() public subHeadings?: string[];

  @HostBinding('class.has-image') public hasImage = false;

  public ngOnChanges(changes: SimpleChanges): void {
    if ('topImageUrl' in changes) this.hasImage = Boolean(this.topImageUrl);
  }
}

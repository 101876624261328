<img *ngIf="topImageUrl" [src]="topImageUrl" />

<div *ngIf="heading" class="heading">{{ heading }}</div>
<div *ngFor="let subheading of subHeadings; last as last" class="sub-heading">
  {{ subheading }}

  <br *ngIf="!last" />
</div>

<ng-content></ng-content>

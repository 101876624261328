import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'lobby',
    loadChildren: () => import(/* webpackChunkName: "lobby" */ './lobby/lobby.module').then((m) => m.LobbyModule),
  },
  {
    path: 'elevator',
    loadChildren: () =>
      import(/* webpackChunkName: "elevator" */ './elevator/elevator.module').then((m) => m.ElevatorModule),
  },
  {
    path: 'office',
    loadChildren: () => import(/* webpackChunkName: "office" */ './office/office.module').then((m) => m.OfficeModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

<app-log-stream></app-log-stream>

<!-- Admin consent is not given or the app initialization failed -->
<iframe *ngIf="showStartTrialPage" class="start-trial" [src]="startTrialPageUrl | safe: 'resourceUrl'"></iframe>

<app-spinner
  *ngIf="!showStartTrialPage && (authenticationInProgress || showSpinner)"
  class="loader"
  size="40px"
></app-spinner>

<ng-container *ngIf="!showStartTrialPage && inTeams">
  <ng-container *transloco="let tError; read: 'entry.error'">
    <!-- User consent not given -->
    <app-error
      *ngIf="
        !authenticationInProgress &&
        !completedAuthentication &&
        !showSpinner &&
        hasInternetConnectivity &&
        !authenticationFailed &&
        !indexedDbInitializationFailed
      "
      [heading]="tError('noUserConsentHeading')"
      [subHeadings]="[tError('noUserConsentSubHeading')]"
    >
      <br />
      <app-button class="absolute-css-values" (buttonClick)="triggerAuthentication()">
        {{ tError("noUserConsentButton") }}
      </app-button>
    </app-error>

    <!-- Getting SSO Token failed -->
    <app-error
      *ngIf="!authenticationInProgress && !showSpinner && hasInternetConnectivity && authenticationFailed"
      [heading]="tError('authenticationFailedHeading')"
      [subHeadings]="[tError('authenticationFailedSubHeading1'), tError('authenticationFailedSubHeading2')]"
      [topImageUrl]="'assets/img/icons/emoji-monocle.png'"
    >
    </app-error>

    <!-- Indexed DB initialization failed -->
    <app-error
      *ngIf="!showSpinner && indexedDbInitializationFailed"
      [heading]="tError('indexedDbInitializationFailedHeading')"
      [subHeadings]="[tError('indexedDbInitializationFailedSubHeading')]"
      [topImageUrl]="'assets/img/icons/emoji-monocle.png'"
    >
    </app-error>

    <!-- No internet connectivity -->
    <app-error
      *ngIf="
        !authenticationInProgress &&
        !showSpinner &&
        !hasInternetConnectivity &&
        !authenticationFailed &&
        enableInitialOfflineScreen
      "
      [subHeadings]="[tError('noInternetConnectivitySubHeading'), tError('reconnecting')]"
      [topImageUrl]="'assets/img/icons/pylon.svg'"
    >
      <br />
      <app-spinner size="30px"></app-spinner>
    </app-error>
  </ng-container>

  <main
    *ngIf="completedAuthentication && isAdminConsentGiven"
    [ngClass]="{
      'hide-content': showSpinner || !hasInternetConnectivity,
      offline: !hasInternetConnectivity
    }"
  >
    <router-outlet></router-outlet>
  </main>
</ng-container>

<app-support-button
  #tourIntroduction="appTour"
  *ngIf="!showSpinner && (authService.isMobileWidth$ | async) === false && !showStartTrialPage"
  tourTooltipDirection="top"
  tourTooltipMinWidth="scale-by-office(40)"
  tourTooltipPadding="scale-by-office(3)"
  tourTooltipPinSize="scale-by-office(1.2)"
  [appTour]="OfficeTourSteps.introduction.identifier"
  [chatFeatureEnabled]="(tenantService.settings?.featureFlags?.chat && !authService.isExternalAccount) || false"
  [isTourActive]="tourIntroduction.showTourTooltip"
  [tourDisablePointerEvents]="tourIntroduction.isActive"
  [tourTooltip]="tourTooltip"
  [tourTooltipOpen]="tourIntroduction.showTourTooltip"
>
  <ng-template #tourTooltip>
    <app-tour-tooltip
      [isFirstStep]="tourIntroduction.isFirstStep"
      [isLastStep]="tourIntroduction.isLastStep"
      [name]="(userFirstName$ | async)!"
      [stepId]="OfficeTourSteps.introduction.identifier"
      [tourId]="'office'"
    />
  </ng-template>
</app-support-button>

@if (!showSpinner && !showStartTrialPage && (authService.isMobileWidth$ | async) === false && (userInfo$ | async)) {
  <app-settings-button
    #tourSettings="appTour"
    appTour
    [tourDisablePointerEvents]="tourSettings.isActive"
    [tourTooltipDisabled]="true"
    (buttonClicked)="openSettings()"
  />
}
